import request from "tools/request.js"

//商品设置 提交
class youhuiquan_set_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		  
		let m_shpi_id 			 	= that.parent.m_shpi_id;
		let session 				= localStorage.getItem("session"); 
		  
		let params = {   
			m_youhuiquan_id : m_shpi_id,
			session : session
		} 
		
		request.post('/youhuiquan/youhuiquan_set_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message,
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_2 = false;
			that.parent.parent.youhuiquan_get.m_main(); 
		})
		 
	}
}

export default youhuiquan_set_post