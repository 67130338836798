<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<br />

 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					用户id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_user_id"></el-input>
				</el-col>
			</el-row>
			<br />

			<el-row>
				<el-col class="yu_input_label" :span="8">
					优惠额度:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_edu"></el-input>
				</el-col>
			</el-row>
			<br />

			 


			 
 



			<el-row class="yu_content_right">
				<el-button @click="youhuiquan_add" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import youhuiquan_add_post from "./expand/youhuiquan_add_post.js";
	// import youhuiquan_add_get from "./expand/youhuiquan_add_get.js";
	
	export default {

		props: ["parent"],
		data() {
			return { 
				m_user_id: "",
				m_edu: "", 
			}
		},
		created() {
			const that = this;
			// that.youhuiquan_add_get = new youhuiquan_add_get(that);
			// that.youhuiquan_add_get.m_main();
		},
		methods: {
			youhuiquan_add() {
				const that = this;
				that.youhuiquan_add_post = new youhuiquan_add_post(that);
				that.youhuiquan_add_post.m_main();
			}, 
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>