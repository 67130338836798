import request from "tools/request.js"

//商品设置 获取
class youhuiquan_set_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
	 
		 let m_shpi_id 		 	 = that.parent.m_shpi_id;
		  let session = localStorage.getItem("session");
		 let params = {
		 	m_youhuiquan_id : m_shpi_id,
			session : session
		 } 
		 
		request.post('/youhuiquan/youhuiquan_set_get',params).then(res => { 
			  
			that.parent.m_youhuiquan_info = res.data.result;  
			console.log(that.parent.m_youhuiquan_info);
		})
		 
	}
}

export default youhuiquan_set_get