<template>
	<div>
		<layout>

			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">用户id:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="m_shpi_name" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col> 
				<el-col :span="16">
					<el-button class="yu_right_1" @click="drawer_1 = true" plain>
						<i class="el-icon-plus"></i>添加优惠券
					</el-button>
				</el-col>
			</el-row> 
			<br />

 
			<br />
			<el-table :data="m_youhuiquan_list" style="width: 100%">
				<el-table-column prop="yohq_id" label="id">
				</el-table-column>
				<el-table-column prop="yohq_no" label="优惠额度">
				</el-table-column>  
				<el-table-column prop="yohq_user_id" label="用户id">
				</el-table-column>  
				<el-table-column prop="yohq_zengsong_time" label="添加时间">
				</el-table-column> 
				<el-table-column prop="yohq_shiyong_time" label="使用时间">
				</el-table-column>
				<el-table-column prop="yohq_state" label="状态">
				</el-table-column> 
				<el-table-column label="操作" show-overflow-tooltip align="right">
					<template slot-scope="scope">
						 <el-button v-if="scope.row.yohq_state =='未使用'" size="mini" type="danger" @click="f_youhuiquan_set(scope.row.yohq_id )">使用优惠券</el-button>
					</template>
				</el-table-column>
			</el-table>
			
			<br />
			<br />
			
			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>
		<el-drawer title="添加优惠券" :visible.sync="drawer_1" size="600px">
			<youhuiquan_add :parent="me"></youhuiquan_add>
		</el-drawer>

		<el-drawer title="使用优惠券" :visible.sync="drawer_2" size="600px">
			<youhuiquan_set :key="m_shpi_id" :m_shpi_id="m_shpi_id" :parent="me"></youhuiquan_set>
		</el-drawer>
	</div>
</template>

<script>
	import layout from 'views/layout.vue'

	import youhuiquan_add from './components/youhuiquan_add/youhuiquan_add'
	import youhuiquan_set from './components/youhuiquan_set/youhuiquan_set'
	import youhuiquan_get from "./expand/youhuiquan_get.js"


	export default {
		components: {
			layout,
			youhuiquan_add,
			youhuiquan_set
		},
		data() {
			return {
				me: this,
				m_shpi_name: "",
				m_youhuiquan_list: [],
				drawer_1: false,
				drawer_2: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_shpi_id : 0,
				m_shpi_shan_id : ""
			}
		},
		created() {
			const that = this;
			if(this.$route.query.shangjia_id >0){
				that.m_shpi_shan_id = this.$route.query.shangjia_id;
			}
			  
			that.youhuiquan_get = new youhuiquan_get(that);
			that.youhuiquan_get.m_main(); 
			 
		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}, 
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.youhuiquan_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;
				
				that.youhuiquan_get.m_main();
			},
			f_youhuiquan_set(shpi_id) {
				const that = this;
				
				that.m_shpi_id = shpi_id;
				that.drawer_2 = true; 
			}
		}
	}
</script>
