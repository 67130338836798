<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<br />
			<el-row>
				<el-col class="yu_input_label" :span="8">
					优惠券id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_youhuiquan_info.yohq_id" disabled></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					用户id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_youhuiquan_info.yohq_user_id" disabled></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row>
				<el-col class="yu_input_label" :span="8">
					优惠额度:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_youhuiquan_info.yohq_no" disabled></el-input>
				</el-col>
			</el-row>
			<br />
			 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					添加时间:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_youhuiquan_info.yohq_zengsong_time" disabled></el-input>
				</el-col>
			</el-row>
			<br />
			
			<el-row class="yu_content_right">
				<el-button @click="youhuiquan_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import youhuiquan_set_get from "./expand/youhuiquan_set_get.js";
	import youhuiquan_set_post from "./expand/youhuiquan_set_post.js";

	export default {

		props: ["m_shpi_id", "parent"],
		data() {
			return {
				m_shpi_shan_id: "",
				m_youhuiquan_info: [], 
			}
		},
		created() {
			const that = this;
			that.youhuiquan_set_get = new youhuiquan_set_get(that);
			that.youhuiquan_set_get.m_main();
		},
		methods: {
			youhuiquan_set() {
				const that = this;
				that.youhuiquan_set_post = new youhuiquan_set_post(that);
				that.youhuiquan_set_post.m_main();
			} 
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
